import { Modal, Button, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const InfoPageCenteredModal = (props) => {
    const { t } = useTranslation();

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className='text-center'>
                <Modal.Title id="contained-modal-title-vcenter" >
                    {t('dashboardContainerFirstVisit')}
                    {/* {t('dashboardContainerFirstVisit', { lng: 'fr' })}
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    {t('dashboardContainerFirstVisit', { lng: 'en' })} */}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className='text-center' style={{ fontSize: 18 }}>
                    {t('dashboardContainerMessageFirstVisit')}
                    {/* {t('dashboardContainerMessageFirstVisit', { lng: 'fr' })}
                    <hr />
                    {t('dashboardContainerMessageFirstVisit', { lng: 'en' })} */}
                </p>
                <div className={'btn-info-cnt'}>
                    <Link to={'/info'}>
                        <Button
                            className='mt-3 btn-info'
                            variant={"dark"}
                            onClick={props.onHide} >
                            {t('dashboardContainerVisitInfoPage')}
                            {/* {t('dashboardContainerVisitInfoPage', { lng: 'fr' })}
                            &nbsp;&nbsp;|&nbsp;&nbsp;
                            {t('dashboardContainerVisitInfoPage', { lng: 'en' })} */}
                        </Button>
                    </Link>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide} variant={"dark"}><FontAwesomeIcon icon={faTimes} /> {t('close')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default InfoPageCenteredModal;