import React from 'react';
import { useAppContext } from "../../useAppState";
import { Link, useLocation } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import certificateIcon from '../../images/certificate-icon.png';


const CertificateDisplay = ({ currentCertificateData, showModal, handleClose, dateEarned }) => {

    const { state, actions } = useAppContext();
    const location = useLocation();

    if (!currentCertificateData) return null;

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = `data:application/pdf;base64,${currentCertificateData.pdf}`;

        const normalizedDate = moment(currentCertificateData.dateEarned).format('YYYY-MM-DD');
        link.download = `certificate_${normalizedDate}.pdf`;
        link.click();
    };

    return (
        <Modal show={showModal} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{state.lang === 'fr' ? 'Certificat obtenu' : 'Certificate earned'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {currentCertificateData.pdf && (
                    <Worker workerUrl="/pdf.worker.min.js">
                        <Viewer fileUrl={`data:application/pdf;base64,${currentCertificateData.pdf}`} />
                    </Worker>
                )}
            </Modal.Body>
            <Modal.Footer style={location.pathname !== '/all-certificates' ? { display: 'flex', justifyContent: 'space-between' } : {}}>
                {
                    location.pathname !== '/all-certificates' && (
                        <Link to={'/all-certificates'}>
                            <Button variant="secondary">
                                {state.lang === 'fr' ? 'Voir tous mes certificats' : 'View all my certificates'}
                                <img
                                    src={certificateIcon}
                                    alt="certificate-icon"
                                    style={{ width: 20, margin: '0 0 0 10px' }} />
                            </Button>
                        </Link>
                    )
                }


                <Button variant="primary" onClick={handleDownload}>
                    {state.lang === 'fr' ? 'Télécharger le certificat' : 'Download certificate'} <FontAwesomeIcon icon={faDownload} />
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CertificateDisplay;