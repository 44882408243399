import React, { useState, useEffect } from "react";
import { useAppContext } from '../../useAppState'
import { gql } from "@apollo/client";
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Modal, Button, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import './BrokerPermitNumberModal.scss';

const ADD_PERMIT_INFO = gql`
  mutation AddPermitInfo(
            $id: ID!, 
            $CIPR: String!, 
    ) {
    addPermitInfo(
            id: $id, 
            CIPR: $CIPR, 
        )
  }
`;


const BrokerPermitNumberModal = (props) => {

    const { state, actions } = useAppContext();

    const { t } = useTranslation();

    const [modalShow, setModalShow] = useState(false);
    const [cipr, setCipr] = useState(state.userInfos.CIPR || '');
    const [error, setError] = useState('');

    const [addPermitInfo] = useMutation(
        ADD_PERMIT_INFO,
        {
            onCompleted(response) {
                console.log(response.addPermitInfo)
                setModalShow(false);
                props.setEditShowModalBrokerPermitNumberFalse();
                window.location.reload();

            },
            onError({ response, operation, graphQLErrors }) {
                console.log(response);
                console.log(graphQLErrors);
            }
        }
    );



    useEffect(() => {
        if (
            props.enabled
            && state.userInfos.profile === 'Broker'
            && (props.editShowModalBrokerPermitNumber || (state.userInfos.CIPR === null || state.userInfos.CIPR === ''))
            && Cookies.get('infoPageVisited') === 'true'
        ) {
            setModalShow(true);
        }

    }, [state.userInfos.CIPR, Cookies.get('infoPageVisited'), props.editShowModalBrokerPermitNumber]);

    const handleConfirm = () => {
        if (!cipr.trim()) {
            setError(state.lang === 'fr' ? 'Le CIPR est manquant' : 'CIPR is missing');
        } else {
            setError('');
            addPermitInfo({
                variables: {
                    id: state.userInfos.id,
                    CIPR: cipr.trim()
                }
            });
        }
    };

    return (
        <Modal
            show={modalShow}
            onHide={() => {
                setModalShow(false)
                props.setEditShowModalBrokerPermitNumberFalse();
            }}
            zIndex={9999}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="custom-modal-width"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter"  >
                    {state.lang === 'fr' ? 'Avant de continuer, veuillez entrer votre CIPR' : 'Before continuing, please enter your CIPR'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <input
                        type="text"
                        value={cipr}
                        onChange={(e) => setCipr(e.target.value)}
                        placeholder={state.lang === 'fr' ? 'Entrez le CIPR' : 'Enter CIPR'}
                        className="form-control"
                        style={{ maxWidth: '300px', margin: '20px auto' }}
                    />
                    {error && <Alert variant="danger" >{error}</Alert>}
                </div>
            </Modal.Body>
            <Modal.Footer>
                {/* <Button onClick={() => setModalShow(false)} variant={"dark"}>
                    <FontAwesomeIcon icon={faTimes} /> {state.lang === 'fr' ? 'Fermer' : 'Close'}
                </Button> */}
                <Button onClick={handleConfirm} variant={"primary"}>
                    <FontAwesomeIcon icon={faCheck} /> {state.lang === 'fr' ? 'Confirmer' : 'Confirm'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default BrokerPermitNumberModal;